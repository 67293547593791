import React, { useState } from "react";
import SearchBox from "../../../../components/SearchBox";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import GeneratorModal from "../../../../components/Administration/Settings/GeneratorModal";
import { useGetGeneratorsQuery, useUpdateGenStatusMutation } from "../../../../services/settings.service";
import { toggleDelete } from "../../../../redux/slices/ui.slice";
import { useDispatch } from "react-redux";
import { DELETE_GENERATOR } from "../../../../services/constants";
import alertify from "alertifyjs";

const GeneratorType = () => {
  const [gen, setGen] = useState(null);
  const {data, isLoading} = useGetGeneratorsQuery();
  const dispatch = useDispatch();
  const [updateStatus, {isLoading: isGenLoading, isSuccess}] = useUpdateGenStatusMutation();
  
  const changeStatus = async (id, index) => {
    await updateStatus(id).then(res => {
      const saveData = res.data
      if (saveData?.success ) {
        alertify.success("Saved successfully");
        // const newArr = [...bookings];
        // const newObj = {...newArr[index]}
        // newObj.job_status = status;
        // newArr[index] = newObj;
        // setBookings(newArr);
      } else {
        alertify.error(saveData?.message);
      }
    })
  }

  const doDelete = (e, item) => {
    console.log(e.currentTarget)
    dispatch(
      toggleDelete({
        open: true,
        title: 'Generator',
        url: `${DELETE_GENERATOR}/${item.id}`,
        ele: e.currentTarget,
        parent: 'tr'
      })
    );
  }

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text, record, index) => (
        <div className="ant-table-row ant-table-row-level-0 ">
          <span>{index + 1}</span>
        </div>
      ),
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => (
        <div className="ant-table-row ant-table-row-level-0 ">
          <span>{text}</span>
        </div>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "Capacity",
      dataIndex: "size",
      render: (text) => (
        <div className="ant-table-row ant-table-row-level-0">
          <span>{text}kva</span>
        </div>
      ),
      sorter: (a, b) => a.size.length - b.size.length,
    },
    
    // {
    //   title: "Total Units",
    //   dataIndex: "units",
    //   render: (text) => (
    //     <div className="ant-table-row ant-table-row-level-0">
    //       <span>{text}</span>
    //     </div>
    //   ),
    //   sorter: (a, b) => a.units.length - b.units.length,
    // },
    // {
    //   title: "Available Units",
    //   dataIndex: "availableUnits",
    //   render: (text) => (
    //     <div className="ant-table-row ant-table-row-level-0">
    //       <span>{text}</span>
    //     </div>
    //   ),
    //   sorter: (a, b) => a.availableUnits.length - b.availableUnits.length,
    // },
    {
      title: "Status",
      sorter: true,
      render: (t,r,idx) => (
        <div className="ant-table-row ant-table-row-level-0 ">
          <Link
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {r.status ? <><i className="far fa-dot-circle text-success" /> Active</> : <><i className="far fa-dot-circle text-danger" /> Inactive</> }
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
          {r.status ?
            <Link to="#" className="dropdown-item" onClick={() => changeStatus(r.id, idx)}>
              <i className="far fa-dot-circle text-danger" /> Inactive
            </Link>
            :
            <Link to="#" className="dropdown-item" onClick={() => changeStatus(r.id, idx)}>
              <i className="far fa-dot-circle text-success" /> Available
            </Link>
          }
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      sorter: true,
      className: "text-end",
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#gen_form"
              onClick={() => setGen(record)}
              
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => doDelete(e, record)}

            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Manage Generators"
            title="Dashboard"
            subtitle="Generators"
            modal="#gen_form"
            name="Add Generator"
            showBtn={true}
          />
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  columns={columns}
                  dataSource={data?.data}
                  rowKey={(record) => record.id}
                  loading={isLoading}
                  pagination={{ defaultPageSize: 100, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50', '100']}}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <GeneratorModal data={gen} onClose={() => setGen(null)} />
      </div>
    </>
  );
};

export default GeneratorType;
